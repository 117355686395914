import React, {useState} from "react";
import { TextField, Button, Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';


const ConnexionGame = (props) => {
    const [username, updateUsername] = useState('');
    const [password, updatePassword] = useState('');
    const [loader, updateLoader] = useState(false);

    const tryConnect = () => {
        if(username === '' || password === ''){
            document.getElementById('alertInputs').style.display = 'block';
        }
        else{
            document.getElementById('alertInputs').style.display = 'none';
            updateLoader(true);
            axios.post('https://api.pp.such-easy.fr/game/auth', {id : props.idGame, password : password, } )
            .then(res => {
                console.log(res);
                if(res.status === 200){
                    updateLoader(false);
                    props.connexionOk({username : username});
                }
                else{
                    updateLoader(false);
                }
            })
            .catch(err => {
                console.log(err.response.status);
                if(err.response.status === 401){
                    document.getElementById('alertPassword').style.display = 'block';
                }
                else if (err.response.status === 404){
                    document.getElementById('alertGame').style.display = 'block';
                }
                updateLoader(false);
            }) 
        }
    }

    return(
        <div className="content">
            <h1>Connexion à la partie</h1>
            <Card className="contentForm">                
                <TextField onChange={(e) => updateUsername(e.target.value)} label="Prénom et nom" />
                <TextField onChange={(e) => updatePassword(e.target.value)} type="password" label="Mot de passe" />
                {
                    loader &&
                    <CircularProgress color="secondary" />
                }
                {
                    !loader && 
                    <Button variant="contained" color="primary" onClick={() => tryConnect()}>
                        Rejoindre la partie
                    </Button>

                }
            </Card>
            <div className="alert" id="alertInputs">
				<p>Un ou plusieurs champs ne sont pas rempli(s)</p>
			</div>
            <div className="alert" id="alertPassword">
				<p>Le mot de passe est incorrect</p>
			</div>
            <div className="alert" id="alertGame">
				<p>La partie n'existe pas ou plus</p>
			</div>
        </div>
    )
}

export default ConnexionGame; 