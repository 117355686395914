import React, {useState} from "react";
import {Button } from '@material-ui/core';
import axios from 'axios';
import ConnexionGame from "./Components/ConnexionGame";
import Game from "./Components/Game";

const GameInterface = (props) => {
    const [connexion, updateConnexion] = useState(false);
    const [username, updateUsername] = useState('')
    const [start, updateStart] = useState(false)
    const [showResult, updateShowResult] = useState(false)
    const [score,updateScore] = useState(0);
    const [rules,updateRules] = useState(false);
	const [dataImage, updateDataImage] = useState([]);

    const connexionOk = (name) => {
        console.log('------',name);
        updateUsername(name.username);
        updateRules(true);
        updateConnexion(true);
		addImage();
    }
    const startGame = () => {
        console.log('START');
        updateRules(false);
        updateStart(true)

    }
	const addImage = () => {
		axios.post('https://api.pp.such-easy.fr/game/findone', {id : props.match.params.idGame})
        .then(res => {
            if(res.status === 200){
				console.log(res.data);
				if(res.data.game_image){
					console.log(res.data.game_image);
					updateDataImage(res.data.game_image);
				}
				console.log(dataImage);
            }
        });
	}


    return(
        <div className="content">
            {
                !connexion && 
                    <ConnexionGame connexionOk={(e) => connexionOk(e)} idGame={props.match.params.idGame}/>
            }
            {
                (rules && !start)&&
                <div className="rules">
                    <h1>Bonjour {username}</h1>
                    <p>Voici les règles : </p>
					<video controls width="100%">
						<source src="/rules.mp4"
								type="video/mp4" />
						Sorry, your browser doesn't support embedded videos.
					</video>

                    <Button variant="contained" color="primary" onClick={() => startGame()}>
                        Jouer
                    </Button>
                </div>
            }
            {
                start &&
                <Game idGame={props.match.params.idGame} dataImage={dataImage} username={username} resultGame={(e) => {updateScore(e.scoreProvi); updateShowResult(true); updateStart(false)}} />
            }
            {
                showResult &&
                <div className="result">
					<img src="/gameOver.png" alt="Game over" width="480" height="287" />

                    <h1>Voici votre score : {score}</h1>
                </div>
            }
        </div>
    )
}

export default GameInterface; 