import React, {useEffect, useState} from "react";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import {FaTrash} from 'react-icons/fa';
import {GrDocumentConfig} from 'react-icons/gr';
import {AiFillCopy, AiOutlineLink} from 'react-icons/ai';
import {GiPodium} from 'react-icons/gi';
import {RiRestartLine} from 'react-icons/ri';
import moment from 'moment';
import IosSwitch from "./Components/iosSwitch";
import { Modal, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Resultat from './Components/Resultat';
import Header from './Components/Header'

const GamesListing = () => {
    const [loader, updateLoader] = useState(true);
    const [games, updateGames] = useState([]);
    const [showModal, updateShowModal] = useState(false);
    const [modalMessage, updateModalMessage] = useState('');
    const [IdForAction, updateIdForAction] = useState('');
    const [typeAction, updateTypeAction] = useState('');
	const [modalForCopy, updateModalForCopy] = useState(false);
    const [resultat, updateResultat] = useState(false);
    const [name, updateName] = useState(false);
	let history = useHistory();

    useEffect(() => {
        findAllGames();
    }, []);      

    const findAllGames = () => {
        axios.get('https://api.pp.such-easy.fr/game/listing')
        .then(res => {
            if(res.status === 200){
                updateGames(res.data);
                updateLoader(false);
            }
        })
    };
    const modalInfo = (message, id,type) => {
		updateModalMessage(message);
		if(type === 'link'){
			updateModalForCopy(true);
			updateModalMessage(window.location.host + '/game/'+ id);
		}
        updateIdForAction(id);
        updateTypeAction(type);
        updateShowModal(true);
    };
    const closeModal = () => {
        updateShowModal(false);
		updateModalForCopy(false);
    }
    const actionGame = () => {
        if(typeAction === "delete"){
            axios.post('https://api.pp.such-easy.fr/game/delete', {id : IdForAction} )
            .then(res => {
                if(res.status === 200){
                    updateGames(res.data);
                    updateShowModal(false);
                }
            })    
        }
        else if(typeAction === "copy"){
            axios.post('https://api.pp.such-easy.fr/game/copy', {id : IdForAction} )
            .then(res => {
                if(res.status === 200){
                    updateGames(res.data);
                    updateShowModal(false);
                }
            })    
        }
        else if(typeAction === "restart"){
            axios.post('https://api.pp.such-easy.fr/game/restart', {id : IdForAction} )
            .then(res => {
                if(res.status === 200){
                    updateGames(res.data);
                    updateShowModal(false);
                }
            })    
        }
    }

    const showResult = (id, name) => {
        updateResultat(true);
		updateName(name)
        updateIdForAction(id);
    }


    return(
        <div>
			<Header />
            <h1>Liste des parties</h1>
            {
                loader &&
                <CircularProgress color="secondary" />
            }
                <table className="tableListing">
                    <thead>
                        <tr>
                            <th>Nom du jeu</th>
                            <th>Accéder au paramétrage</th>
                            <th>Nombre de colonne</th>
                            <th>Nombre de ligne</th>
                            <th>Fermé/Ouvert</th>
                            <th>Nombre de joueurs</th>
                            <th>Nombre de joueurs restants</th>
							<th>Temps maximum</th>
                            <th>Meilleur score</th>
                            <th>Nom meilleur joueur</th>
                            <th>Voir les résultats</th>
                            <th>Recommencer la partie</th>
                            <th>Copier</th>
                            <th>Supprimer</th>
                            <th>Voir le lien</th>
                            <th>Date de mise à jour</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        games.map((value,index) => {
                            return(
                                <tr key={index}>
                                    <td>{value.name}</td>
                                    <td onClick={() => history.push(`/admin/newgame/${value.id}`)}><GrDocumentConfig/></td>
                                    <td>{value.nbColumn}</td>
                                    <td>{value.nbLine}</td>
                                    <td>
                                        <IosSwitch state={value.status} id={value.id}/>
                                    </td>
                                    <td>{value.nbPlayers}</td>
                                    <td>{value.remaining_players}</td>
									<td>{value.maxTime}</td>
                                    <td>{value.best_score}</td>
                                    <td>{value.best_score_player}</td>
                                    <td><GiPodium  onClick={() => showResult(value.id, value.name)}/></td>
                                    <td><RiRestartLine onClick={() => modalInfo(`Voulez-vous réinitialiser la partie ${value.name}`, value.id, 'restart')} /></td>
                                    <td><AiFillCopy onClick={() => modalInfo(`Voulez-vous copier la partie ${value.name}`, value.id, 'copy')} /></td>
                                    <td><FaTrash onClick={() => modalInfo(`Etes vous sur de vouloir supprimer la partie ${value.name}`, value.id, 'delete') }/></td>
                                    <td><AiOutlineLink onClick={() => modalInfo(`${value.name}`, value.id, 'link') }/></td>
                                    <td>{moment(value.updatedAt).format('DD/MM/YYYY')}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            {
                showModal &&
                <Modal
                    open={showModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Card style={{display:'flex', justifyContent: 'space-around', padding: '10px'}}>
                        <p>{modalMessage}</p>
						{
							!modalForCopy &&
							<div className="button">
								<Button variant="contained" color="primary" onClick={() => actionGame()}>
									Oui
								</Button>
								<Button variant="contained" color="secondary" onClick={() => closeModal()}>
									Non
								</Button>
							</div>
						}
						{
							modalForCopy && 
							<Button variant="contained" color="secondary" onClick={() => closeModal()}>
								Fermer
							</Button>
						}
                    </Card>
                </Modal>
            }
            {
                resultat &&
                <Resultat id={IdForAction} name={name} closeModal={() => updateResultat(false)} />
            }
        </div>
    )
}

export default GamesListing; 