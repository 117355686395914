import {useState, useEffect, useCallback} from 'react';
import Chrono from './Chrono';
import axios from 'axios';
import _ from 'lodash';
import {Button } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
let dataForDrop = [];
let dataIntoCellule = {};
let response =[];


const Game = (props) => {
    const [score,updateScore] = useState(0);
    const [objColumn, updateObjColumn] = useState(undefined);
    const [objLine, updateObjLine] = useState(undefined);
    const [allCase,updateAllCase] = useState([]);
    const [loader, updateLoader] = useState(true);
    const [numberTry, updateNumberTry] = useState(2);
    const [chrono, updateChrono] = useState('');
	const [dataImage, updateDataImage] = useState({});
	const [maxTime, updateMaxTime] = useState(null);
	const [description, updateDescription] = useState('');
    const [decount, updateDecount] = useState('');

    useEffect(() => {
		updateDataImage(props.dataImage);
        axios.post('https://api.pp.such-easy.fr/game/findone', {id : props.idGame})
        .then(res => {
            if(res.status === 200){
				updateObjColumn(res.data.column);
				updateObjLine(res.data.line);
				updateMaxTime(res.data.maxTime);
				updateDescription(res.data.description);
                updateDecount(res.data.maxTime)
            }
        });
    }, [props.idGame, props.dataImage]);

    useEffect(() => {
        if(objLine){
            objLine.map((value, index) => {
                allCase.push([]);
                generateCard(value);
                generateCase(value, index);
            })
            updateLoader(false);
        }
    }, [objLine]);

	useEffect(() => {
        if(maxTime){
            const minutesChrono = chrono.split(':')[0];
            const secondesChrono = chrono.split(':')[1];
            const secondesAll = (parseFloat(minutesChrono)*60) + (parseFloat(secondesChrono)+1);
            const minutesDecount = maxTime.split(':')[0];
            const secondesDecount = maxTime.split(':')[1];
            const secondesAllDecount = (parseFloat(minutesDecount)*60) + parseFloat(secondesDecount);

            const secondesAddition = secondesAllDecount - secondesAll;
            var Finalminutes = Math.floor(secondesAddition / 60);
            var Finalseconds = secondesAddition - Finalminutes * 60;
            const final =  Finalseconds.toString().length === 1 ? Finalminutes + ':0' + Finalseconds :Finalminutes + ':' + Finalseconds;
            updateDecount(final)
        }
		if(maxTime === chrono){
			let scoreProvi = 0;
			for (const [key, value] of Object.entries(dataIntoCellule)) {
				if(value){
					const responseCase = value.split('_')[1];
					// GOOD RESPONSE
					if(key === responseCase){
						scoreProvi = scoreProvi + 10;
					}
					else{
						const columnDestination = key.split('B')[0];
						let valueColumnEmplacement = value.split('_')[0];
						valueColumnEmplacement = valueColumnEmplacement.split('B')[0];
	
						const lineDestination = 'B' + key.split('B')[1];
						let valueLineEmplacement = value.split('_')[0];
						valueLineEmplacement = 'B' + valueLineEmplacement.split('B')[1];
	
						// GOOD COLUMN
						if(columnDestination === valueColumnEmplacement || lineDestination === valueLineEmplacement){
							scoreProvi = scoreProvi + 2;
						}
						else {
							scoreProvi = scoreProvi - 2;
						}
					}
				}
			}
			axios.post('https://api.pp.such-easy.fr/game/player/add', {id_game : props.idGame, score : scoreProvi, time : chrono, name: props.username })
			.then(res => {
				if(res.status === 200){
					updateObjColumn(res.data.column);
					updateObjLine(res.data.line);
				}
				props.resultGame({score : scoreProvi});
			});

		}
	}, [chrono])
    
    const generateCard = (data) => {

        // Generate tab for response
        for (const [key, value] of Object.entries(data)) {
            if(key.length > 3){
                dataForDrop.push(value + '_' + key);
                response.push({key, value});
            }
        }
        dataForDrop =_.shuffle(dataForDrop);
    }
    const generateCase = (data, index) => {
        //Generate tab for all case
        for (const [key, value] of Object.entries(data)) {
            allCase[index].push({key,value});
        } 
		// updateAllCase(_.shuffle(allCase));
    }
    const onDragEnd = useCallback((result) => {
        console.log(result);
		if(result.destination){
            if(result.source.droppableId === "cardToSelect" && result.destination.droppableId === "cardToSelect"){}
			else if(result.source.droppableId === "cardToSelect"){
				dataForDrop.pop();
				if(dataIntoCellule[result.destination.droppableId]){
					dataForDrop.push(dataIntoCellule[result.destination.droppableId]);
				}
				dataIntoCellule[result.destination.droppableId] = result.draggableId;
			}
			else{
				document.getElementById(result.source.droppableId).classList.remove('success');
				document.getElementById(result.source.droppableId).classList.remove('danger');
				document.getElementById(result.source.droppableId).classList.remove('attention');
				dataIntoCellule[result.source.droppableId] = undefined;
				if(result.destination.droppableId === "cardToSelect"){
					dataForDrop.push(result.draggableId);
				}
                else if(dataIntoCellule[result.destination.droppableId]){
                    console.log('else if')
                    dataForDrop.push(dataIntoCellule[result.destination.droppableId]);
                    dataIntoCellule[result.destination.droppableId] = result.draggableId;
                }
                else{
                    console.log('else')
                //    dataForDrop.push(result.draggableId);
                    dataIntoCellule[result.destination.droppableId] = result.draggableId;
                }
			};
			if(document.getElementById(result.destination.droppableId)){
				document.getElementById(result.destination.droppableId).classList.remove('success');
				document.getElementById(result.destination.droppableId).classList.remove('danger');
				document.getElementById(result.destination.droppableId).classList.remove('attention');
			}
		}
    }, []);
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'var(--primary-light-color)' : 'var(--secondary-light-color)',
        // height: '125px'
    });

    const checkAllData = () => {
        console.log(dataForDrop.length)
        if(dataForDrop.length !== 0){
            document.getElementById('alertCard').style.display = 'block';
        }
        else{
            console.log('2')
			let scoreProvi = 0;
			document.getElementById('alertCard').style.display = 'none';
			for (const [key, value] of Object.entries(dataIntoCellule)) {
                console.log(value);
				if(value){
					const responseCase = value.split('_')[1];
					// GOOD RESPONSE
					if(key === responseCase){
						scoreProvi = scoreProvi + 10;
						document.getElementById(key).classList.add('success');
					}
					else{
						const columnDestination = key.split('B')[0];
						let valueColumnEmplacement = value.split('_')[1];
						valueColumnEmplacement = valueColumnEmplacement.split('B')[0];
	
						const lineDestination = 'B' + key.split('B')[1];
						let valueLineEmplacement = value.split('_')[1];
						valueLineEmplacement = 'B' + valueLineEmplacement.split('B')[1];

						// GOOD COLUMN
						if(columnDestination === valueColumnEmplacement || lineDestination === valueLineEmplacement){
							document.getElementById(key).classList.add('attention');
							scoreProvi = scoreProvi + 2;
						}
						else {
							document.getElementById(key).classList.add('danger');
							scoreProvi = scoreProvi - 2;
						}
					}
                    console.log('3', scoreProvi);
					updateScore(scoreProvi);
				}
			}
            updateNumberTry(numberTry - 1);
            if(numberTry === 1){
				const minutesChrono = chrono.split(':')[0];
				const secondesChrono = chrono.split(':')[1];
				const secondesProgress = (parseFloat(minutesChrono)*60) + (parseFloat(secondesChrono)+1);
				
				const minutesMaxTime = maxTime.split(':')[0];
				const secondesMaxTime = maxTime.split(':')[1];
				const secondesAllMax = (parseFloat(minutesMaxTime)*60) + (parseFloat(secondesMaxTime));

				const finalTime = Math.round((secondesAllMax - secondesProgress)/60);
				const score = finalTime*scoreProvi;
				
                finishGame(score);
            }
		}
    }

    const finishGame = (scoreProvi) => {
        axios.post('https://api.pp.such-easy.fr/game/player/add', {id_game : props.idGame, score : scoreProvi, time : chrono, name: props.username })
        .then(res => {
            console.log('score : ' ,scoreProvi)
            if(res.status === 200){
                updateObjColumn(res.data.column);
                updateObjLine(res.data.line);
            }
			props.resultGame({scoreProvi});
        });
    };
    
    if(!loader){
        return(
            <div className="contentGame">
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                <div className="topBar">
                <Droppable
                    droppableId="cardToSelect"
                    >
                    {(provided, snapshot) => (
                        <div className="elementsForDrag" {...provided.droppableProps} ref={provided.innerRef} >
                        <p className="titleCase">Pioche (cartes restantes : {dataForDrop.length})</p>
                        {
                            dataForDrop.map((value, index) => {
                                return(
                                    <Draggable key={`card-${index}`} draggableId={dataForDrop[index]} index={index}>
                                    {(provided) => (
                                        <div className="card" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											{
												dataImage[dataForDrop[index].split('_')[1]] &&
												<img src={`https://api.pp.such-easy.fr/images/${props.idGame}/${dataImage[dataForDrop[index].split('_')[1]].name}`} className="imgTab" alt={dataImage[dataForDrop[index].split('_')[1]].name} />
											}
                                            <p>{dataForDrop[index].split('_')[0]}</p>
											{provided.placeholder}
                                        </div>
                                    )}
                                    </Draggable>
                                )
                            })                    
                    }
					{provided.placeholder}
                    </div> 
                    )}
                </Droppable>
                        <div className="score">
                            {/* <p className="titleCase">Score :</p>
                            <p>{score}</p> */}
                            <p className="titleCase">Titre :</p>
                            <p>{description}</p>
                        </div>
                    <Chrono  maxTime={maxTime} chrono={(e) => updateChrono(e)}/>
                    <div className="chronometer">
                        <p className="titleCase">Temps :</p>
                        <p>{decount}</p>
                    </div>
                </div>
                {/* <p className="descGame">{description}</p> */}
                <div className="tab">
                    {
                        (objColumn && objLine) &&
                        <table>
                            <thead>
                                <tr>
                                    <th className="columnCreate"></th>
                                    {
                                        objColumn.map((value, index) => {
                                            return(
                                                <th id={'A'+index} className="columnCreate" key={'A'+index}>
													{
														dataImage['A'+(index+1)] && 
														<img src={`https://api.pp.such-easy.fr/images/${props.idGame}/${dataImage['A'+(index+1)].name}`} className="imgTab" alt="" />
													}
													<p>{Object.values(value)}</p>
												</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                allCase.map((data, index) => {
                                    return(
                                        <tr id={'B' + (index+1)} key={'B' + (index+1)}>
                                            {
                                                data.map((element, id) => {
                                                    if(element.key.length < 4){
                                                        return(
                                                            <td key={element.value} id={element.key} className="line">
																{
																	dataImage[(element.key)] && 
																	<img src={`https://api.pp.such-easy.fr/images/${props.idGame}/${dataImage[element.key].name}`} className="imgTab" alt="" />
																}
																<p>{element.value}</p>
															</td>
                                                        )
                                                    }
                                                    else{
                                                        return(
                                                            <Droppable droppableId={element.key} key={element.key}>
                                                            {(provided, snapshot) => (
                                                                <td  style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} ref={provided.innerRef} key={element.value} id={element.key} className="case">
                                                                    {dataIntoCellule[element.key] && 
                                                                        <Draggable key={dataIntoCellule[element.key]} draggableId={dataIntoCellule[element.key]} index={1}>
                                                                            {(provided) => (
                                                                                <div className="cardTable" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																					{
																						dataImage[dataIntoCellule[element.key].split('_')[1]] && 
																						<img src={`https://api.pp.such-easy.fr/images/${props.idGame}/${dataImage[dataIntoCellule[element.key].split('_')[1]].name}`} className="imgTab" alt="" />
																					}
                                                                                    <p>{dataIntoCellule[element.key].split('_')[0]}</p>
																					{provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    }
																	{provided.placeholder}
                                                                </td>
                                                            )}
                                                            </Droppable>
                                                        )
                                                    }

                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    }
                    <Button variant="contained" color="primary" onClick={() => checkAllData()}>
                        Valider
                    </Button>
                    <p>Essaie restant {numberTry}</p>
                    <div className="alert" id="alertCard">
                        <p>Il reste des cartes à placer</p>
                    </div>
                </div>
                </DragDropContext>
        </div>
        )
    }
    else{
        return(
            <p>Chargement ...</p>
        )
    }
}

export default Game;