import {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { useHistory } from 'react-router';
const LoginAdmin = () => {
    const [login, updateLogin] = useState(undefined);
    const [password, updatePassword] = useState(undefined);
    let history = useHistory();

    const auth = () => {
        axios.post('https://api.pp.such-easy.fr/admin/auth' , {login, password})
        .then(res => {
            localStorage.setItem('token',res.data);
            history.push('/admin/gameslisting');
        })
        .catch(err => {
            console.log(err);
        });
    }

    return(
        <div className="formLogin">
            <h1>Connexion Admin</h1>
            <form onSubmit={(e) => { e.preventDefault();}}>
                <TextField id="login" onChange={(e) => updateLogin(e.target.value)} label="Identifiant" variant="filled" />            
                <TextField id="password" onChange={(e) => updatePassword(e.target.value)} label="Mot de passe" variant="filled" type="password" />            
                <Button onClick={auth} variant="contained" color="primary">
                    Envoyer
                </Button>  
            </form>
            <div className="logo">
                <img className="logoAzertyLogin" src="/azerty.png" alt="" />
            </div>
        </div>
    )
}

export default LoginAdmin;