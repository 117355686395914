import React from 'react';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';

const Header = () => {
	let history = useHistory();

	const redirect = (path) => {
		history.push(path);
		if(path === '/admin/login'){
			localStorage.clear();
		}
	}

	return(
			<div className="header">
                <img className="logoAzertyHeader" src="/azerty.png" alt="" />
				<Button variant="contained" color="primary" onClick={() => redirect('/admin/gameslisting')}>
					Toutes les parties
				</Button>
				<Button variant="contained" color="primary" onClick={() => redirect('/admin/newgame')}>
					Créer une partie
				</Button>
				<Button variant="contained" color="primary" onClick={() => redirect('/admin/login')}>
					Déconnexion
				</Button>
			</div>
	)
}

export default Header;