import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//FIXME fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset} ceci est un texte long`,
        val: `${k + offset}`,
        key: `card-${k + offset}`,
        image: `https://picsum.photos/300/300/?random=${k + offset} `,
    }));

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination, sourceNum, destinationNum ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[sourceNum] = sourceClone;
    result[destinationNum] = destClone;

    return result;
};

const columnNumber = 10;
const lineNumber = 10;

const getItemStyle = (isDragging, draggableStyle,index) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 2,
    position: 'absolute',
    ...draggableStyle 
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'var(--primary-light-color)' : 'var(--secondary-light-color)',
    borderRadius: '5px',

});

const getListStyleMultiple = isDraggingOver => ({
    background: isDraggingOver ? 'var(--primary-light-color)' : 'var(--secondary-dark-color)',
    borderRadius: '5px',

});

const getListStylePioche = isDraggingOver => ({
    background: isDraggingOver ? 'var(--primary-light-color)' : 'var(--secondary-light-color)',
});


class Start extends React.Component {
    state = {
        items: [],
    };

    onDragEnd = result => {
        const { source, destination } = result;
        if (source==null || destination==null)
            return;

        var sourceNum = source.droppableId.replace( /^\D+/g, '');
        var destinationNum = destination.droppableId.replace( /^\D+/g, '');

        console.log("from droppableId "+source.droppableId);
        console.log("to droppableId "+destination.droppableId);

        if (destinationNum===1000)
            return;

        if (source.droppableId === destination.droppableId) {
            return;   
        }
        {
            const dstresult = move(
                this.state.items[sourceNum],
                this.state.items[destinationNum],
                source,
                destination,
                sourceNum,
                destinationNum
            );

            let val = {
                items: this.state.items,
            }

            val.items[sourceNum]=dstresult[sourceNum]
            val.items[destinationNum]=dstresult[destinationNum]

            this.setState({
                items: val.items,
            });

            console.log(this.state);
        }
    };

    createTable = () => {
        let children = []
        let arraySize = ((columnNumber)*(lineNumber))
        for (let j = 1; j <= arraySize ; j++) {
        let a=(<Droppable droppableId={"droppable"+j}>
                {(provided, snapshot) => (
                    <div className={"stack"+j}
                    ref={provided.innerRef}
                    style={ (this.state.items[j].length > 1) ? 
                        getListStyleMultiple(snapshot.isDraggingOver) :
                        getListStyle(snapshot.isDraggingOver)
                    }>
                    {this.state.items[j].map((item, index) => (
                        <Draggable
                            key={j+index}
                            draggableId={item.id}
                            index={index}>
                            {(providedsub, snapshotsub) => (
                                <div
                                    key={j+index+arraySize}
                                    className="card"
                                    ref={providedsub.innerRef}
                                    {...providedsub.draggableProps}
                                    {...providedsub.dragHandleProps}
                                    style={getItemStyle(
                                        snapshotsub.isDragging,
                                        providedsub.draggableProps.style,
                                        item.val
                                    )}>
                                    <div className="cardcontent">
                                    {item.content}
                                    </div>
                                    <img src={item.image} className="image" />
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
            </Droppable> )
            children.push(a)
        }
        for (let j = 1; j <= columnNumber; j++) {
            let a=(
                <div className={"column"+j} >
                    Colonne {j}
                </div>
            )
            children.push(a)
        }
        for (let j = 1; j <= lineNumber; j++) {
            let a=(
                <div className={"line"+j} >
                    Ligne {j}
                </div>
            )
            children.push(a)
        }
        
        return children
    }
    
    createStyleOfTitleDivs(){
        var style = document.createElement('style');
        let styleContent = ''
        for (let j = 1; j <= columnNumber; j++) {
            styleContent+=`
                .column${j}  {
                    display: flex;
                    flex: none;
                    justify-content: center;
                    align-items: center;
                    grid-area: 1 / ${j+1} / 2 / ${j+2};
                    background: var(--color-title-column);
                    border-radius: 10px;
                    color: #FFFFFF;
                    font-style: bold;
                }
            `
        }
        for (let j = 1; j <= lineNumber; j++) {
            styleContent+=`
                .line${j}  {
                    display: flex;
                    flex: none;
                    justify-content: center;
                    align-items: center;
                    grid-row: ${j+1} / ${j+2};
                    grid-column: 1;
                    background: var(--color-title-column);
                    border-radius: 10px;
                    color: #FFFFFF;
                    font-style: bold;
                }
            `
        }
        style.innerHTML = styleContent;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    createStyleOfGamePlayDivs(){
        var style = document.createElement('style');
        let styleContent = ''
        let pos=1
        for (let i = 1; i <= lineNumber; i++) {
            for (let j = 1; j <= columnNumber; j++) {
                styleContent+=`
                    .stack${pos}  {
                        display: flex;
                        flex: none;
                        justify-content: center;
                        align-items: center;
                        grid-area: ${i+1} / ${j+1} / ${i+2} / ${j+2};
                    }
                `
                pos+=1
            }
        }
        style.innerHTML = styleContent;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    createStyleOfInfoDivs(){
        var style = document.createElement('style');
        let styleContent = `
            .grid-container  {
                display: grid;
                grid-template-columns: repeat(${columnNumber}+1, 3fr);
                grid-template-rows: 1fr repeat(${lineNumber}, 3fr);
                grid-column-gap: 4px;
                grid-row-gap: 4px;
                width: 99vw;
                height: 70vh;
                flex: none;

            }
            .card_disable {
                width: ${85/(columnNumber+2)}vw;
                height: ${70/(lineNumber+2)}vh; 
            }
            .card {
                width: ${80/(columnNumber+3)}vw;
                height: ${70/(lineNumber+3)}vh; 
                flex-shrink: 1;
                background-size: contain;
                background-repeat: no-repeat;
                background-position:right;
                background: var(--primary-color);
                flex: none;
                box-shadow: 3px 3px 3px gray;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
            }

            .cardcontent {
                padding: 2px;
                color: black;
                display: flex;
                flex-direction: column;
                font-weight: 600;
                font-size: 0.9em;
            }

        `
        style.innerHTML = styleContent;
        document.getElementsByTagName('head')[0].appendChild(style);
    }


    constructor(props) {
        super(props);
        //TODO déplacer ça proprement
        let a = []
        a[1000]=getItems(columnNumber*lineNumber);
        for (let j = 1; j < ((columnNumber+2)*(lineNumber+1)+1); j++) {
            a[j]=[]
        }
        this.state = { items: a };
    }

    componentDidMount(){
        console.log("mount !")
        this.createStyleOfInfoDivs();
        this.createStyleOfTitleDivs();
        this.createStyleOfGamePlayDivs();
    }        


    render() {
        return (
            <div>
                <div className="define-grid">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable1000">
                            {(provided, snapshot) => (
                                <div className="pioche"
                                ref={provided.innerRef}
                                style={getListStylePioche(snapshot.isDraggingOver)}>
                                {this.state.items[1000].map((item, index) => (
                                <Draggable
                                key={"pioched"+index}
                                draggableId={item.id}
                                index={index}>
                                {(providedsub, snapshotsub) => (
                                    <div
                                        key={"piochec"+index}
                                        className="card"
                                        ref={providedsub.innerRef}
                                        {...providedsub.draggableProps}
                                        {...providedsub.dragHandleProps}
                                        style={getItemStyle(
                                            snapshotsub.isDragging,
                                            providedsub.draggableProps.style,
                                            item.val
                                        )}>
                                        <div className="cardcontent">
                                            {item.content}
                                        </div>
                                        <img src={item.image} className="image" />
                                    </div>
                                )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                        </Droppable>

                        <div className="points" >
                            <h3>10 000</h3>
                        </div>
                        <div className="chrono" >
                            <h3>42:42</h3>
                        </div>                    
                        <div className="Game">
                            <div className="grid-container">
                                    { this.createTable() }

                            </div>
                        </div>
                    </DragDropContext>
                        <div className="Controls">
                            GO !
                        </div>
                </div>
            </div>
        );
    }
}

export default Start;
