import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from '@material-ui/core';

let columnNumber = 5
let lineNumber = 5

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#e7ff8c' : '#e2f1f8',
    borderRadius: '5px',
});



class Start extends React.Component {
    state = {
        items: [],
        open: false,
        stackid: 0,
        modalContent: null  ,
        stackDesc: [],
    };

    createTable = () => {
        let children = []
        let arraySize = ((columnNumber)*(lineNumber))
        for (let j = 1; j <= arraySize ; j++) {
        let a=(<div className={"stack"+j}
                        style={getListStyle(true)}
                        onClick={() => this.onStackclick(j)}
                    >
                    
                </div>
            )
            children.push(a)
        }

        
        return children
    }
    
    createStyleOfTitleDivs(){
        var style = document.createElement('style');
        let styleContent = ''
        for (let j = 1; j <= columnNumber; j++) {
            styleContent+=`
                .column${j}  {
                    display: flex;
                    flex: none;
                    justify-content: center;
                    align-items: center;
                    grid-area: 1 / ${j+1} / 2 / ${j+2};
                    background: #e2f1f8;
                    border-radius: 10px;
                }
            `
        }
        for (let j = 1; j <= lineNumber; j++) {
            styleContent+=`
                .line${j}  {
                    display: flex;
                    flex: none;
                    justify-content: center;
                    align-items: center;
                    grid-row: ${j+1} / ${j+2};
                    grid-column: 1;
                    background: #e2f1f8;
                    border-radius: 10px;
                }
            `
        }
        style.innerHTML = styleContent;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    createStyleOfGamePlayDivs(){
        var style = document.createElement('style');
        let styleContent = ''
        let pos=1
        for (let i = 1; i <= lineNumber; i++) {
            for (let j = 1; j <= columnNumber; j++) {
                styleContent+=`
                    .stack${pos}  {
                        display: flex;
                        flex: none;
                        justify-content: center;
                        align-items: center;
                        grid-area: ${i+1} / ${j+1} / ${i+2} / ${j+2};
                    }
                `
                pos+=1
            }
        }
        style.innerHTML = styleContent;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    createStyleOfInfoDivs(){
        var style = document.createElement('style');
        let styleContent = `
            .grid-container  {
                display: grid;
                grid-template-columns: repeat(${columnNumber}+1, 3fr);
                grid-template-rows: 1fr repeat(${lineNumber}, 3fr);
                grid-column-gap: 3px;
                grid-row-gap: 3px;
                width: 99vw;
                height: 70vh;
                flex: none;

            }
            .card_disable {
                width: ${85/((columnNumber)*2)}vw;
                height: ${70/((lineNumber+4)*2)}vh; 
            }
            .card {
                width: ${80/((columnNumber)*2)}vw;
                height: ${70/((lineNumber+4)*2)}vh; 
                flex-shrink: 1;
                background-size: contain;
                background-repeat: no-repeat;
                background-position:right;
                background-color: #b2ff59;
                display: flex;
                flex: none;
                box-shadow: 2px 2px 2px gray;
                border-radius: 5px;
            }

        `
        style.innerHTML = styleContent;
        document.getElementsByTagName('head')[0].appendChild(style);
    }


    constructor(props) {
        super(props);
        columnNumber = parseInt(sessionStorage.getItem('nbcolumn'));
        lineNumber = parseInt(sessionStorage.getItem('nbline'));
        let a = []
        for (let j = 1; j < ((columnNumber*lineNumber)+1); j++) {
            a[j]=[]
        }
        this.state = { stackDesc: a };
    }

    componentDidMount(){
        this.createStyleOfInfoDivs();
        this.createStyleOfTitleDivs();
        this.createStyleOfGamePlayDivs();
    }        

    showModal = () => {
        this.setState({ open: true });
    };
    
    hideModal = () => {
        this.setState({ open: false });
    };

    onDescChange = (stackid,event) => {
        let stackDesc=this.state.stackDesc;
        stackDesc[stackid]=event.target.value;
        this.setState({ stackid: stackid});
        this.setState({ stackDesc: stackDesc});
    }


    getModalContent = () => {
        return ( <div className="modalBody">
                <p>Case {this.state.stackid} </p>
                <TextField id="standard-basic" value={this.state.stackDesc[this.state.stackid]} label="Description" onChange={(e) => this.onDescChange(this.state.stackid,e)}  />
                <Button>Choisir une image</Button>
                <br /> <br />
                <Button onClick={ this.hideModal }>Sauvegarder</Button>
                <br />
            </div>);
    }

    onStackclick = stackid => {
        this.setState({
            stackid: stackid,
            modalContent:
                <div className="modalBody">
                    <p>Case {stackid} </p>
                    <TextField id="standard-basic" value={this.state.stackDesc[stackid]} label="Description" onChange={(e) => this.onDescChange(stackid,e)}  />
                    <Button>Choisir une image</Button>
                    <br /> <br />
                    <Button onClick={ this.hideModal }>Sauvegarder</Button>
                    <br />
                </div>
            });
        this.showModal();
    };
   
    render() {
        return (
            <div>
                <div className="define-grid-param">
                    
                        <div className="Game">
                            <div className="grid-container">
                                    { this.createTable() }
                            </div>
                        </div>
                        <div className="Controls">
                            Controls
                        </div>
                        <Modal 
                            className="paramModal" 
                            open={this.state.open} 
                            onClose={this.hideModal} 
                            disableBackdropClick={true} 
                            hideBackdrop={true}
                        >
                            {this.getModalContent()}
                        </Modal>
                </div>
            </div>
        );
    }
}

export default Start;
