import {useState, useEffect, useRef} from 'react';
function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      let id = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(id);
    }, [delay]);
}
const Chrono = (props) => {
    const [secondes, setSecondes] = useState('00');
    const [minutes, setMinutes] = useState('00');

    useInterval(() => {
        let newValue = parseFloat(secondes) + 1;
        if(newValue < 10){
            newValue = '0' + newValue;
            setSecondes(newValue);
        }
        else if(newValue === 60){
            if(minutes < 10){
                setMinutes('0' + (parseFloat(minutes) + 1));
            }
            else{
                setMinutes((parseFloat(minutes) + 1));

            }
            setSecondes('00')
        }
        else{
            setSecondes(newValue);
        }
        props.chrono(minutes + ':' + secondes);
      }, 1000);

    
    return(
        <div className="chronometer none">
            <p className="titleCase">Temps :</p>
            <p>{minutes} : {secondes}</p>
        </div>
    )
}
export default Chrono;