import React from 'react';
import { Modal, Card, TextField, Button } from '@material-ui/core';

let objColumn = [];
let objLine = [];
let imageObj = {};
let newImage = {};
let dataForm = new FormData();
class Start extends React.Component {
	constructor(props) {
        objColumn = [];
        objLine = [];
        imageObj = {};
        dataForm = new FormData();
		super(props);
		this.state = {
			nbColumn : props.nbColumn,
			nbLine : props.nbLine,
			stateModal : false,
			titleModal : '',
			typeModal : '',
			idModal : null,
			newValue : '',
			existingPicture : false,
		};
		this.showColumn(props.nbColumn);
		this.showLine(props.nbLine);
		this.showCase(props.nbColumn,props.nbLine);
		if(props.restart){
			objColumn = props.objColumn;
			objLine = props.objLine;
            if(props.restartImage){
                imageObj = props.restartImage;
            }
		}
	}
	componentWillUpdate(nextProps, nextState){
		this.showColumn(nextProps.nbColumn);
		this.showLine(nextProps.nbLine);
		this.showCase(nextProps.nbColumn,nextProps.nbLine);
	}
	showColumn(nbColumn) {
		// objColumn = [];

		for (let index = 0; index < nbColumn; index++) {
			if(!objColumn[index]){
				objColumn.push({ ['A' + (index+1)] : 'Colonne ' + (index+1) });
			}
		}
		if(objColumn.length > nbColumn){
			for (let index = 10; index > nbColumn; index--) {
				if(objColumn[index-1]){
					objColumn.pop();
				}
			}
		}
		this.props.onChangeColumn(objColumn);
	}
	showLine(nbLine) {
		for (let index = 0; index < nbLine; index++) {
			if(!objLine[index]){
				objLine.push({ ['B' + (index+1)] : 'Ligne ' + (index+1) });
			}
		}
		if(objLine.length > nbLine){
			for (let index = 10; index > nbLine; index--) {
				if(objLine[index-1]){
					objLine.pop();
				}
			}
		}
		this.props.onChangeColumn(objColumn);
	}
	showCase(nbColumn, nbLine){
		for (let index = 0; index < nbLine; index++) {
			const tab = objLine[index];
			let nbdata = 0;
			for (const [key] of  Object.entries(tab)) {
				nbdata++;
			}
			for (let i = 0; i < nbColumn; i++) {
				if(!tab['A' + (i+1) + 'B' + (index+1)]){
					tab['A' + (i+1) + 'B' + (index+1)] = 'A' + (i+1) + 'B' + (index+1);
				}
			}
			if(nbdata-1 > nbColumn){
				for (let increment = 10; increment > nbColumn; increment--) {
					delete tab['A' + increment + 'B' + (index+1)]
				}
			}
		}
		this.props.onChangeLine(objLine);	
	}
	generateCase(data){
		let arrayData = [];
		for (const [key, value] of Object.entries(data)) {
			let a;
			if(imageObj[(key)] && this.props.restart){
				a =(
					<td onClick={() => this.openModal(key, value, 'line')} id={key} key={key} className="case">
                        <div className="caseContent">
						    <img className='imgTab' src={`https://api.pp.such-easy.fr/images/${this.props.idGame}/${imageObj[key].name}`} alt={'image-' + key} />
						    <p id={'--'+key}>{value}</p>
                        </div>
					</td>
				)
			}
			else if((newImage[key] && this.props.restart)){
				a =(
					<td onClick={() => this.openModal(key, value, 'line')} id={key} key={key} className="case">
                        <div className="caseContent">
						    <img className='imgTab' src={newImage[key].preview} alt={'image-' + key} />
						    <p id={'--'+key}>{value}</p>
                        </div>
					</td>
				)
			}
			else if(imageObj[key]){
				a =(
					<td onClick={() => this.openModal(key, value, 'line')} id={key} key={key} className="case">
                        <div className="caseContent">
						    <img className='imgTab' src={imageObj[key].preview} alt={'image-' + key} />
						    <p id={'--'+key}>{value}</p>
                        </div>
					</td>
				)
			}
			else{
				a=(
					<td onClick={() => this.openModal(key, value, 'line')} id={key} key={key} className="case">
                        <div className="caseContent">
						    <p id={'--'+key}>{value}</p>
                        </div>
					</td>
				)
			}
			arrayData.push(a);
		}
		return(
			arrayData
		)
	}
	openModal(idCol, oldValue, type){
		this.setState({titleModal : oldValue});
		this.setState({idModal : idCol});
		this.setState({typeModal : type});
		this.setState({stateModal : true});
		let id;
		if(type === "column"){
			id = 'A'+(this.state.idModal+1)
		}
		else if(idCol.length < 4){
			const spliter = idCol.split('B');
			id = 'B'+(spliter[1])
		}
		else{
			id = idCol;
		}
		if(imageObj[id]){
			this.setState({existingPicture : true});
		}
	}
	saveValue(){
		if(this.state.typeModal === "column"){
			const tab = objColumn[this.state.idModal];
			tab['A'+(this.state.idModal + 1)] = this.state.newValue;
			document.getElementById('A--'+(this.state.idModal)).innerHTML = this.state.newValue;
			this.props.onChangeColumn(objColumn);
			if(document.getElementById('uploadFile').files.length !== 0){
				const data = new FormData();
				data.append(`file`, document.getElementById('uploadFile').files[0]);
				dataForm.append('file', document.getElementById('uploadFile').files[0], 'A'+(this.state.idModal))
				if(this.props.restart){
					newImage['A'+(this.state.idModal+1)] = ({data :document.getElementById('uploadFile').files[0], preview : (URL.createObjectURL(document.getElementById('uploadFile').files[0])), name: 'A'+(this.state.idModal) + '.' + document.getElementById('uploadFile').files[0].type.split('/')[1] });
				}else{
					imageObj['A'+(this.state.idModal+1)] = ({data :document.getElementById('uploadFile').files[0], preview : (URL.createObjectURL(document.getElementById('uploadFile').files[0])), name: 'A'+(this.state.idModal) + '.' + document.getElementById('uploadFile').files[0].type.split('/')[1] });
				}
			}
		}
		else if(this.state.idModal.length < 4){
			const spliter = this.state.idModal.split('B');
			const tab = objLine[spliter[1] - 1];
			tab[this.state.idModal] = this.state.newValue;
			document.getElementById('--B'+(spliter[1])).innerHTML = this.state.newValue;
			this.props.onChangeLine(objLine);
			if(document.getElementById('uploadFile').files.length !== 0){
				const data = new FormData();
				dataForm.append('file', document.getElementById('uploadFile').files[0], 'B'+(spliter[1]))
				data.append(`file`, document.getElementById('uploadFile').files[0]);
				if(this.props.restart){
					newImage['B'+(spliter[1])] = ({data :document.getElementById('uploadFile').files[0], preview : (URL.createObjectURL(document.getElementById('uploadFile').files[0])), name: 'B'+(spliter[1]) + '.' + document.getElementById('uploadFile').files[0].type.split('/')[1]});
				}else{
					imageObj['B'+(spliter[1])] = ({data :document.getElementById('uploadFile').files[0], preview : (URL.createObjectURL(document.getElementById('uploadFile').files[0])), name: 'B'+(spliter[1]) + '.' + document.getElementById('uploadFile').files[0].type.split('/')[1]});
				}
			}
		}
		else{
			const spliter = this.state.idModal.split('B');
			const tab = objLine[spliter[1] - 1];
			tab[this.state.idModal] = this.state.newValue;
			document.getElementById('--' + this.state.idModal).innerHTML = this.state.newValue;	
			this.props.onChangeLine(objLine);
			if(document.getElementById('uploadFile').files.length !== 0){
				const data = new FormData();
				data.append(`file`, document.getElementById('uploadFile').files[0]);
				dataForm.append('file', document.getElementById('uploadFile').files[0], this.state.idModal);
				console.log(document.getElementById('uploadFile').files[0]);
				if(this.props.restart){
					newImage[this.state.idModal] = ({data :document.getElementById('uploadFile').files[0] , preview : (URL.createObjectURL(document.getElementById('uploadFile').files[0])), name: this.state.idModal + '.' + document.getElementById('uploadFile').files[0].type.split('/')[1]});
				}else{
					imageObj[this.state.idModal] = ({data :document.getElementById('uploadFile').files[0] , preview : (URL.createObjectURL(document.getElementById('uploadFile').files[0])), name: this.state.idModal + '.' + document.getElementById('uploadFile').files[0].type.split('/')[1]});
				}
			}
		}
		this.setState({stateModal : false});
		console.log('imageObj', imageObj);
		console.log('newImage',newImage);
		const all = {...imageObj,...newImage};
		console.log('Join',imageObj);
		this.props.imageObj(dataForm);
		this.props.imageForBDD({all});
	}

	deletePicture(){
		let id;
		if(this.state.typeModal === "column"){
			id = 'A'+(this.state.idModal+1)
		}
		else if(this.state.idModal.length < 4){
			const spliter = this.state.idModal.split('B');
			id = 'B'+(spliter[1])
		}
		else{
			id = this.state.idModal;
		}
		delete imageObj[id];
		this.setState({existingPicture : false});
		this.setState({stateModal : false});
	}

	render() {
	  return (
		<div>
		  <table>
			<thead>
				<tr>
					<th className="columnCreate"></th>
					{
						objColumn.map((value, index) => {
							console.log(imageObj)
							if(imageObj['A'+(index+1)] && this.props.restart){
								return(
									<th onClick={() => this.openModal(index, Object.values(value), 'column')} id={'A'+index} className="columnCreate" key={'A'+index}><img className='imgTab' src={`https://api.pp.such-easy.fr/images/${this.props.idGame}/${imageObj['A'+(index+1)].name}`} alt={'image-A' + index} /> <p id={'A--'+index}> {Object.values(value)}</p></th>
								)
							}
							else if(newImage['A'+(index+1)] && this.props.restart){
								return(
									<th onClick={() => this.openModal(index, Object.values(value), 'column')} id={'A'+index} className="columnCreate" key={'A'+index}><img className='imgTab' src={newImage['A'+(index+1)].preview} alt={'image-A' + index} /> <p id={'A--'+index}> {Object.values(value)}</p></th>
								)
							}
							else if(imageObj['A'+(index+1)]){
								return(
									<th onClick={() => this.openModal(index, Object.values(value), 'column')} id={'A'+index} className="columnCreate" key={'A'+index}><img className='imgTab' src={imageObj['A'+(index+1)].preview} alt={'image-A' + index} /> <p id={'A--'+index}> {Object.values(value)}</p></th>
								)
							}
							else{
								return(
									<th onClick={() => this.openModal(index, Object.values(value), 'column')} id={'A'+index} className="columnCreate" key={'A'+index}> <p id={'A--'+index}>{Object.values(value)}</p></th>
								)
							}
						})
					}
				</tr>
			</thead>
			<tbody>
				{
					objLine.map((value, index) => {
						return(
							<tr key={'B'+index}>
									{this.generateCase(value)}
							</tr>
						)
					})
				}
			</tbody>
		</table>
		<Modal
			open={this.state.stateModal}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Card style={{display:'flex', justifyContent: 'space-around', padding: '10px'}}>
				<p>{this.state.titleModal}</p>
				<TextField onChange={(e) => this.setState({newValue : e.target.value})} label="Valeur" />
				<input type="file" id="uploadFile" accept=".png, .jpg, .jpeg" />
				{
					this.state.existingPicture &&
					<Button variant="contained" color="primary" onClick={() => this.deletePicture()}>
					Supprimer la photo
					</Button>
				}
				<Button variant="contained" color="primary" onClick={() => this.saveValue()}>
					Sauvegarder
				</Button>
			</Card>
		</Modal>
		</div>
	  );
	}
}
export default Start;
