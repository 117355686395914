import React from 'react';
import ReactDOM from 'react-dom';
import Start from './GameOld';
import Param from './Param';
import NewGame from './NewGame';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from "react-router-dom";
import GamesListing from './GamesListing';
import GameInterface from './GameInterface';
import LoginAdmin from './LoginAdmin';
import PrivateRoute from './PrivateRoute';


// ReactDOM.render(
//   <React.StrictMode>
//     <Start />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <BrowserRouter>
		<Switch>
			<Route exact path="/" component={LoginAdmin} />
			<Route exact path="/admin/login" component={LoginAdmin} />
			<Route exact path="/game/:idGame" component={GameInterface} />
			<PrivateRoute exact path="/admin/param" component={Param} />
			<PrivateRoute exact path="/admin/newgame" component={NewGame} />
			<PrivateRoute exact path="/admin/newgame/:idGame" component={NewGame} />
			<PrivateRoute exact path="/admin/gameslisting" component={GamesListing} />
		</Switch>
  </BrowserRouter>, document.getElementById('root')
);
