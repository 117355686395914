import {useEffect, useState} from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";
import moment from 'moment';

const Resultat = (props) => {
    const [resultat, updateResultat] = useState(undefined);
    const [description, updateDescription] = useState('');
	const [objectForCsv, updateObjectForCsv] = useState([]);

    useEffect(() => {
        axios.post('https://api.pp.such-easy.fr/game/result', {id : props.id} )
        .then(res => {
            if(res.status === 200){
                updateResultat(res.data.findAllScore);
                updateDescription(res.data.description);
                createCSV(res.data.findAllScore);
            }
        });
        const interval = setInterval(() => {
            axios.post('https://api.pp.such-easy.fr/game/result', {id : props.id} )
            .then(res => {
                if(res.status === 200){
                    updateResultat(res.data.findAllScore);
                    createCSV(res.data.findAllScore);
                }
            });          
        }, 2000);
          return () => clearInterval(interval);
    }, [props.id]);

    const closeModal = () => {
        props.closeModal(true);
    };

	const createCSV = (data) => {
		console.log(data);
		let csvArray = [["Nom", "Score", "Temps"]];

		data.map((value,index) => {
			console.log(value)
			csvArray.push([value.name, value.score, value.time]);
		});

		updateObjectForCsv(csvArray);
	}

    return(
        <div className="modalScore">
            <div className="resultat">
                <img className="logoAzerty" src="/azerty.png" alt="" />
                <h1>Résultat pour {props.name}</h1>
                <p>{description}</p>
                <div className="rank headerTable">
                    <p>Place</p>
                    <p>Nom</p>
                    <p>Score</p>
                    <p>Temps</p>
                </div>
                {
                    resultat &&
                    resultat.map((value,index) => {
                        return(
                            <div className="rank" key={index}>
                                <p>{index+1}</p>
                                <p>{value.name}</p>
                                <p>{value.score}</p>
                                <p>{value.time}</p>
                            </div>
                        )
                    })
                }
                <div className="close" onClick={() => closeModal()}>X</div>
				<CSVLink filename={'Résultat-'+ props.name + '-' + moment().format('DD-MM-YYYY') + '.csv'} data={objectForCsv}>Télécharger le csv</CSVLink>
			</div>
        </div>
    )
}

export default Resultat; 