import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Table from './Components/CreateTable';
import { Button, InputLabel, TextField, Card, Select, MenuItem } from '@material-ui/core';
import Header from './Components/Header'

const NewGame = (props) => {
	const [nameGame, updateNameGame] = useState('')
	const [nbPlayers, updateNbPlayers] = useState(0)
	const [passwordGame, updatePasswordGame] = useState('');
	const [descriptionGame, updateDescriptionGame] = useState('');
	const [nbLine, updateNbLine] = useState(1);
	const [nbColumn, updateNbColumn] = useState(1);
	const [objLine,updateObjLine] = useState({});
	const [objColumn,updateObjColumn] = useState({});
	const [imgObject,updateImgObject] = useState({});
	const [restart, updateRestart] = useState(false);
	const [loadingTab, updateLoadingTab] = useState(false);
	const [imageForBDD, updateImageForBDD] = useState(undefined);
	const [maxTimeGame, updateMaxTimeGame] = useState(undefined);
	let history = useHistory();

	useEffect(() => {
		if(props.match.params.idGame){
			axios.post('https://api.pp.such-easy.fr/game/findone', {id : props.match.params.idGame})
			.then(res => {
				if(res.status === 200){
					updateNameGame(res.data.name);
					updateNbPlayers(res.data.nbPlayers);
					updateNbLine(res.data.nbLine);
					updateNbColumn(res.data.nbColumn);
					updateObjLine(res.data.line);
					updateObjColumn(res.data.column);
					updateImgObject(res.data.game_image);
					updateDescriptionGame(res.data.description);
                    updateMaxTimeGame(res.data.maxTime);
					updateRestart(true);
					updateLoadingTab(true);
					updateImageForBDD(res.data.game_image)
				}
			})
		}
		else{
			updateLoadingTab(true);
		}
	}, [props.match.params.idGame]);


    const onChangeColumn = (e) => {
		updateObjColumn(e);
    }
    const onChangeLine = (e) => {
		updateObjLine(e);
    }
	const imageObj = (e) => {
		updateImgObject(e)
	}

	const saveData = () => {
		if(nameGame === '' || nbPlayers === '' ){
			document.getElementById('alertInputs').style.display = 'block';
		}
		else if (!restart) {
			if(passwordGame === ''){
				document.getElementById('alertInputs').style.display = 'block';
			}
			else{
				let image = imageForBDD ? imageForBDD.all : imageForBDD;
				axios.post('https://api.pp.such-easy.fr/game/create', {objColumn, objLine, nameGame, nbPlayers, passwordGame, nbColumn, nbLine, imageForBDD : image, maxTime : maxTimeGame, descriptionGame})
				.then(res => {
					axios.post('https://api.pp.such-easy.fr/upload' , imgObject ,{headers : {'id' : res.data, 'Content-Type': 'multipart/form-data'}}).then(res => history.push("/admin/gameslisting")).catch(err => console.log(err));
				})
				.catch(err => {
					console.log(err)
				}
				)
			}
		}
		else{
			// axios.post('https://api.pp.such-easy.fr/game/update', {id : props.match.params.idGame, objColumn, objLine, nameGame,maxTime : maxTimeGame, nbPlayers, passwordGame, nbColumn, nbLine,descriptionGame})
			// .then(res => {
			// 	history.push("/admin/gameslisting");
			// })
			// .catch(err => {
			// 	console.log(err)
			// }
			// )
			console.log('image', imageForBDD);
			let image = imageForBDD.all ? imageForBDD.all : imageForBDD;
			console.log(image)
			axios.post('https://api.pp.such-easy.fr/game/update', {id : props.match.params.idGame, objColumn, objLine,imageForBDD : image, nameGame,maxTime : maxTimeGame, nbPlayers, passwordGame, nbColumn, nbLine,descriptionGame})
			.then(res => {
				axios.post('https://api.pp.such-easy.fr/upload' , imgObject ,{headers : {'id' : res.data, 'Content-Type': 'multipart/form-data'}}).then(res => history.push("/admin/gameslisting")).catch(err => console.log(err));
			})
			.catch(err => {
				console.log(err)
			}
			)
		}
	}

	return (
		<div>
			<Header />
			<h1>Création de la partie</h1>
			<Card style={{display:'flex', justifyContent: 'space-around', padding: '10px'}}>
				<div className="name">
					<TextField id="nameGame" className="inputs" value={nameGame} onChange={(e) => updateNameGame(e.target.value)} label="Nom de la partie" />
				</div>
				<div className="nbPlayers">
					<TextField id="nbPlayers" className="inputs" value={nbPlayers} onChange={(e) => updateNbPlayers(e.target.value)} type="number" label="Nombre de joueur(s)" />
				</div>
				<div className="passwordGame">
					<TextField id="passwordGame" className="inputs" onChange={(e) => updatePasswordGame(e.target.value)} type="password" label="Mot de passe" />
				</div>
				<div className="maxTime">
					<TextField id="maxTimeGame" className="inputs" value={maxTimeGame} onChange={(e) => updateMaxTimeGame(e.target.value)} label="Temps (00:00)" />
                    {/* <TextField id="nameGame" className="inputs" value={nameGame} onChange={(e) => updateNameGame(e.target.value)} label="Nom de la partie" /> */}

				</div>
				<div>
					<InputLabel id="nbLine">Nombre de lignes</InputLabel>
					<Select
						labelId="nbLine"
						value = {nbLine}
						onChange={(e) => updateNbLine(e.target.value)}
						>
                        <MenuItem value={1}>1</MenuItem>
						<MenuItem value={2}>2</MenuItem>
						<MenuItem value={3}>3</MenuItem>
						<MenuItem value={4}>4</MenuItem>
						<MenuItem value={5}>5</MenuItem>
						<MenuItem value={6}>6</MenuItem>
						<MenuItem value={7}>7</MenuItem>
						<MenuItem value={8}>8</MenuItem>
						<MenuItem value={9}>9</MenuItem>
						<MenuItem value={10}>10</MenuItem>
					</Select>
				</div>
				<div>
					<InputLabel id="nbColumn">Nombre de colonnes</InputLabel>
					<Select
						labelId="nbColumn"
						value = {nbColumn}
						onChange={(e) => updateNbColumn(e.target.value)}
						>
                        <MenuItem value={1}>1</MenuItem>
						<MenuItem value={2}>2</MenuItem>
						<MenuItem value={3}>3</MenuItem>
						<MenuItem value={4}>4</MenuItem>
						<MenuItem value={5}>5</MenuItem>
						<MenuItem value={6}>6</MenuItem>
						<MenuItem value={7}>7</MenuItem>
						<MenuItem value={8}>8</MenuItem>
						<MenuItem value={9}>9</MenuItem>
						<MenuItem value={10}>10</MenuItem>
					</Select>
				</div>
			</Card>
            <div className="description">
                <TextField id="nameDescription" className="inputs" value={descriptionGame} onChange={(e) => updateDescriptionGame(e.target.value)} label="Description" />
            </div>
			<div className="Game">
				<div className="grid-container" style={{ paddingTop: '10px'}}>
					{
						loadingTab && 
							<Table nbColumn={nbColumn} nbLine={nbLine} imageForBDD={(e) => updateImageForBDD(e)} restartImage={imgObject} imageObj={imageObj} onChangeColumn={onChangeColumn} onChangeLine={onChangeLine} objColumn={objColumn} objLine={objLine} restart={restart} idGame={props.match.params.idGame}/>
					}
				</div>
			</div>
			<Button variant="contained" color="primary" onClick={() => saveData() }>
				Enregistrer le tableau
			</Button>
			<div className="alert" id="alertInputs">
				<p>Un ou plusieurs champs ne sont pas rempli(s)</p>
			</div>
		</div>
    )
}
export default NewGame;